























































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class InfoModal extends Vue {
  content: Array<string>;
  infoModal: any;
  title: string;
  currentLanguage: string;
  introText1: string;
  introText2: string;
  introText3: string;
  link1: string;
  link2: string;
  textList: string;
  supporters: Array<object>;

  constructor() {
    super();    
    this.infoModal = this.$store.state.siteData.modal.infoModal;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.supporters = this.infoModal[this.currentLanguage].supporters;
    this.title = this.infoModal[this.currentLanguage].title;
    this.introText1 = this.infoModal[this.currentLanguage].intro_text1;
    this.introText2 = this.infoModal[this.currentLanguage].intro_text2;
    this.introText3 = this.infoModal[this.currentLanguage].intro_text3;
    this.link1 = this.infoModal[this.currentLanguage].link1;
    this.link2 = this.infoModal[this.currentLanguage].link2;
    this.textList = this.infoModal[this.currentLanguage].text_list;
  }

  closeModal(modal: string) {
    this.$modal.hide('info-modal')
    this.$store.dispatch('show' + modal + 'Modal', false);
  }
}

