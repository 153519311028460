

















import {Component, Vue} from 'vue-property-decorator';


@Component
export default class SocialMedia extends Vue {
  videoSteps: any;
  video: any;
  tweetElm: any;
  currentVideoID: any;
  currentLanguage: string;
  shareText: string;
  shareTextFB: string;
  videoDecision: string;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.video = this.$store.state.video;
    this.currentVideoID = this.video.Id;
    this.videoDecision = this.video.decision;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.shareText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['shareText'];
    this.shareTextFB = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['shareTextFB'];
    this.tweetElm = document.getElementById('tweet');
  }

  mounted() {
    if (this.currentVideoID == '9' && this.videoDecision == 'a') {
      if(this.currentLanguage == 'de') {
        this.shareText = 'Dieses Wochenende habe ich die richtigen Entscheidungen getroffen und hatte ein super Date mit Nina. Alle Entscheidungen richtig getroffen. Was meinst du? Kriegst du das auch hin?'
      } else if (this.currentLanguage == 'fr') {
        this.shareText = 'J\'ai pris les bonnes décisions ce week-end! Et toi? Tu penses pouvoir arriver?'
      }else if (this.currentLanguage == 'it') {
        this.shareText = 'Questo fine settimana ho preso le decisioni giuste e ho avuto un grande date con Nina. Allora, cosa ne pensi? Riuscirai anche tu?'
      }
    }
    this.tweetElm = document.getElementById('tweet');
    const tweetSite = 'https://datenina.ch/';
    const tweetUrl = 'https://twitter.com/intent/tweet?text=' + this.shareText + ' ' + tweetSite;
    this.tweetElm.setAttribute('href', tweetUrl);
  }

  shareBtn() {
    if (this.currentVideoID == '9' && this.videoDecision == 'a') {
      if(this.currentLanguage == 'de') {
        this.shareText = 'Dieses Wochenende habe ich die richtigen Entscheidungen getroffen und hatte ein super Date mit Nina. Alle Entscheidungen richtig getroffen. Was meinst du? Kriegst du das auch hin?'
      } else if (this.currentLanguage == 'fr') {
        this.shareText = 'J\'ai pris les bonnes décisions ce week-end! Et toi? Tu penses pouvoir arriver?'
      }else if (this.currentLanguage == 'it') {
        this.shareText = 'Questo fine settimana ho preso le decisioni giuste e ho avuto un grande date con Nina. Allora, cosa ne pensi? Riuscirai anche tu?'
      }
    }
    this.$store.dispatch('shareBtn', this.shareText);
  }
}

