





































import {Component, Vue, Watch} from 'vue-property-decorator';
import ContactForm from '@/components/forms/contactForm.vue'

@Component({
  components: {
    ContactForm,
  }
})
export default class ContactModal extends Vue {

  @Watch('formModel', {immediate: true, deep: true})
  onFormModelChange(val: string, oldVal: string) {
    this.$store.dispatch('setContactFormData', val)
  }

  content: Array<string>;
  userData: Array<string>;
  friendsData: Array<string>;
  contactModal: any;
  title: string;
  thanksMessageTitle: string;
  thanksMessage: string;
  thanksMessageFail: string;
  thanksMessageTitleFail: string;
  currentLanguage: string;
  text: string;
  maxFieldsFriends: number;
  formModel = {};

  constructor() {
    super();
    this.content = this.$store.state.siteData;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.userData = this.$store.state.userData;
    this.friendsData = this.$store.state.friendsData;
    this.contactModal = this.$store.state.siteData.modal.contactModal;
    this.title = this.contactModal[this.currentLanguage].title;
    this.text = this.contactModal[this.currentLanguage].text;
    this.maxFieldsFriends = this.$store.state.maxFieldNumber;
    this.thanksMessageTitle = this.contactModal[this.currentLanguage].thanksMessageTitle;
    this.thanksMessage = this.contactModal[this.currentLanguage].thanksMessage;
    this.thanksMessageFail = this.contactModal[this.currentLanguage].thanksMessageFail;
    this.thanksMessageTitleFail = this.contactModal[this.currentLanguage].thanksMessageTitleFail;
  }

  closeModal(modal: string) {
    this.$modal.hide('contact-modal')
    this.$store.dispatch('show' + modal + 'Modal', false);
  }

  get showThanks() {
    return this.$store.state.showContactSuccess
  }

  set showThanks(value) {
    this.$store.dispatch('showContactSuccess', value)
  }

  get sentContactFormError() {
    return this.$store.state.sentContactFormError
  }

  set sentContactFormError(value) {
    this.$store.dispatch('sentContactFormError', value)
  }

}


