







































































import store from '@/store'
import {Component, Vue} from 'vue-property-decorator';
import SocialMedia from "@/components/social-media_basic.component.vue";


@Component({
  components: {
    SocialMedia,
  },
})

export default class InputField extends Vue {
  contactModal: any;
  currentLanguage: string;
  sendBtn: string;
  clientNamePlaceholder: string;
  clientEmailPlaceholder: string;
  friendNamePlaceholder: string;
  friendEmailPlaceholder: string;
  InputKey: number;

  constructor() {
    super();
    this.contactModal = this.$store.state.siteData.modal.contactModal;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.sendBtn = this.contactModal[this.currentLanguage].sendBtn;
    this.InputKey = 0;
    this.clientNamePlaceholder = this.contactModal[this.currentLanguage]['inputField'].clientNamePlaceholder;
    this.clientEmailPlaceholder = this.contactModal[this.currentLanguage]['inputField'].clientEmailPlaceholder;
    this.friendNamePlaceholder = this.contactModal[this.currentLanguage]['inputField'].friendNamePlaceholder;
    this.friendEmailPlaceholder = this.contactModal[this.currentLanguage]['inputField'].friendEmailPlaceholder;
  }

  addInput() {
    store.dispatch('addInput')
  }

  removeInput(InputKey: number) {
    this.InputKey = InputKey;
    store.dispatch('removeInput', this.InputKey);
  }

  submitForm() {
    this.$store.dispatch('sendingContactForm', true);
  }

}
