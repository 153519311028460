


































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class FooterComponent extends Vue {
  content: any;
  currentLanguage: string;
  infoTitle: string;
  imprintTitle: string;
  privacyTitle: string;

  constructor() {
    super();
    this.content = this.$store.state.siteData.content;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.infoTitle = this.content[this.currentLanguage]['footer'].info;
    this.imprintTitle = this.content[this.currentLanguage]['footer'].imprint;
    this.privacyTitle = this.content[this.currentLanguage]['footer'].privacy;
  }

  showModal(ModalType: string) {
    this.$store.dispatch('show' + ModalType + 'Modal', true);
  }
}
