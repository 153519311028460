













import {Component, Vue} from "vue-property-decorator";
import SocialMedia from "@/components/social-media.component.vue";

@Component({
  components: {
    SocialMedia
  }
})
export default class BadFinishComponent extends Vue {
  videoSteps: string;
  video: any;
  currentVideoID: any;
  currentLanguage: string;
  endText: string;
  endTextFail: string;
  altFinishBtnText: string;
  nextVideoID: string;
  backLink: string;
  videoSrc: string;
  decAText: string;
  decBText: string;
  videoELm: any;
  backFromFinish: boolean;
  videoDuration: number;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.currentVideoID = this.$store.state.video.Id;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.videoSrc = this.$store.state.video.videoUrl;
    this.videoDuration = this.$store.state.video.videoCurrentTime;
    this.backFromFinish = this.$store.state.video.backFromFinish;
    this.endText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['b'].endText;
    this.altFinishBtnText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].altFinishBtnText;
    this.endTextFail = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['b'].endTextFail;
    this.decAText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].decAText;
    this.decBText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].decBText;
    this.nextVideoID = this.currentVideoID;
    this.backLink = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].backLink;
  }

  mounted() {
    this.videoELm = document.getElementById('video');
  }

  goBack() {
    this.currentVideoID = this.backLink.charAt(0);
    this.$store.dispatch('setVideoId', this.currentVideoID);
    this.$store.state.badEnd = false;
    this.$store.state.showChoices = true;
  }
}

