



















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ShareComponent extends Vue {
  siteContent: Array<string>;
  content: any;
  currentLanguage: string;
  shareBtnTitle: string;

  constructor() {
    super();
    this.siteContent = this.$store.state.siteData;
    this.content = this.$store.state.siteData.content;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.shareBtnTitle = this.content[this.currentLanguage]['ShareBtn'];
  }

  showModal(ModalType: string) {
    this.$store.dispatch('show' + ModalType + 'Modal', true);
  }
}
