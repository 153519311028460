
















import {Component, Vue} from "vue-property-decorator";

@Component
export default class StartComponent extends Vue {
  videoSteps: string;
  video: any;
  currentVideoID: any;
  currentLanguage: any;
  introText: string;
  linkText: string;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.currentVideoID = this.$store.state.video.Id;
    this.currentLanguage = this.$store.state.currentLanguage;

    this.introText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].introText;
    this.linkText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage].linkText;
  }

  startDate() {
    this.$store.dispatch('startDate');
    this.$store.dispatch('setVideoContent', this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]);
    setTimeout(() => {
      this.$store.dispatch('playVideo');
    }, 25)
  }
}
