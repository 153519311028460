






import {Component, Vue} from 'vue-property-decorator';

@Component
export default class FullScreenComponent extends Vue {
  videoContainer: any;
  video: any;

  constructor() {
    super();
    this.video = this.$store.state.video;

  }

  mounted() {
    this.videoContainer = document.getElementById('video-container');
  }

  switchFS() {
    if (!this.video.isFullScreen) {
      if (this.videoContainer.requestFullscreen) {
        this.videoContainer.requestFullscreen();
      } else if (this.videoContainer.msRequestFullscreen) {
        this.videoContainer.msRequestFullscreen();
      } else if (this.videoContainer.mozRequestFullScreen) {
        this.videoContainer.mozRequestFullScreen();
      } else if (this.videoContainer.webkitRequestFullscreen) {
        this.videoContainer.webkitRequestFullscreen();
      }
      this.$store.dispatch('isFullScreen', true)
    } else if (this.video.isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      this.$store.dispatch('isFullScreen', false)
    }
  }

}

