






























































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class PrivacyModal extends Vue {
  contact: Array<string>;
  privacyModal: any;
  title: string;
  subtitle1: string;
  subtitle2: string;
  subtitle1Text: string;
  addressTitle: string;
  addressCity: string;
  addressTel: string;
  addressFax: string;
  email: string;
  subtitle2Text1: string;
  subtitle2Text2: string;
  infoLinkText1: string;
  infoLink1: string;
  infoLinkText2: string;
  infoLink2: string;
  logFileTitle: string;
  logFileText: string;
  currentLanguage: string;

  constructor() {
    super();
    this.currentLanguage = this.$store.state.currentLanguage;
    this.contact = this.$store.state.siteData.contact;
    this.addressTitle = this.contact[this.currentLanguage].addressTitle;
    this.addressCity = this.contact[this.currentLanguage].addressCity;
    this.addressTel = this.contact[this.currentLanguage].addressTel;
    this.addressFax = this.contact[this.currentLanguage].addressFax;
    this.email = this.contact[this.currentLanguage].email;
    this.privacyModal = this.$store.state.siteData.modal.privacyModal;
    this.title = this.privacyModal.title;
    this.subtitle1 = this.privacyModal.subtitle1;
    this.subtitle2 = this.privacyModal.subtitle2;
    this.subtitle1Text = this.privacyModal.subtitle1Text;
    this.subtitle2Text1 = this.privacyModal.subtitle2Text1;
    this.subtitle2Text2 = this.privacyModal.subtitle2Text2;
    this.infoLinkText1 = this.privacyModal.infoLinkText1;
    this.infoLink1 = this.privacyModal.infoLink1;
    this.infoLinkText2 = this.privacyModal.infoLinkText2;
    this.infoLink2 = this.privacyModal.infoLink2;
    this.logFileTitle = this.privacyModal.logFileTitle;
    this.logFileText = this.privacyModal.logFileText;
  }

  closeModal(modal: string) {
    this.$modal.hide('privacy-modal')
    this.$store.dispatch('show' + modal + 'Modal', false);
  }
}

