




















































































import {Component, Vue} from 'vue-property-decorator';
import VideoComponent from './components/video.component.vue';
import FooterComponent from './components/footer.component.vue';
import ShareComponent from './components/share.component.vue';
import InfoModal from "@/components/modale/info.modal.vue";
import ImprintModal from "@/components/modale/imprint.modal.vue";
import PrivacyModal from "@/components/modale/privacy.modal.vue";
import ContactModal from "@/components/modale/contact.modal.vue";
import store from '@/store'

@Component({
  components: {
    VideoComponent,
    FooterComponent,
    ShareComponent,
    InfoModal,
    ImprintModal,
    PrivacyModal,
    ContactModal,
  },
})

export default class App extends Vue {
  isFullScreen: boolean;
  videoELm: any;
  currentLanguage: string;

  constructor() {
    super();
    this.isFullScreen = this.$store.state.video.isFullScreen;
    this.videoELm = document.getElementById('video');
    this.currentLanguage = this.$store.state.currentLanguage;
  }

  mounted() {
    this.$modal.hideAll();
    if (this.showInfoModal) {
      this.$modal.show('info-modal')
    }
    if (this.showImprintModal) {
      this.$modal.show('imprint-modal')
    }
    if (this.showPrivacyModal) {
      this.$modal.show('privacy-modal')
    }
    if (this.showContactModal) {
      this.$modal.show('contact-modal')
    }
    store.watch(
        (state) => {
          return state.showInfoModal
        }, () => {
          this.showModal('info')
        }
    )
    store.watch(
        (state) => {
          return state.showImprintModal
        }, () => {
          this.showModal('imprint')
        }
    )
    store.watch(
        (state) => {
          return state.showPrivacyModal
        }, () => {
          this.showModal('privacy')
        }
    )
    store.watch(
        (state) => {
          return state.showContactModal
        }, () => {
          this.showModal('contact')
        }
    )
    store.watch(
        (state) => {
          return state.sentContactForm
        }, (newValue, oldValue) => {
          if (newValue) {
            this.$store.dispatch('showContactSuccess', true);
          }
        }
    )
    document.addEventListener('fullscreenchange', function () {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        store.dispatch('isFullScreen');
      }
    })
  }

  showModal(modal: string) {
    this.$modal.show(modal + '-modal')
  }

  get showInfoModal() {
    return this.$store.state.showInfoModal
  }

  get showImprintModal() {
    return this.$store.state.showImprintModal
  }

  get showPrivacyModal() {
    return this.$store.state.showPrivacyModal
  }

  get showContactModal() {
    return this.$store.state.showContactModal
  }

  beforeModalClose(modal: string) {
    if (modal === 'Contact') {
      this.$store.dispatch('showThanks', false);
      this.$store.dispatch('sentContactFormError', false);
      this.$store.dispatch('sendingContactForm', false);
      this.$store.dispatch('showContactSuccess', false);
    }
    this.$store.dispatch('show' + modal + 'Modal', false);
  }
}

