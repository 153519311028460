


















import {Component, Vue} from 'vue-property-decorator';


@Component
export default class SocialMedia extends Vue {
  videoSteps: any;
  video: any;
  tweetElm: any;
  currentVideoID: any;
  currentLanguage: string;
  shareText: string;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.video = this.$store.state.video;
    this.currentVideoID = this.video.Id;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.shareText = this.videoSteps['1']['content'][this.currentLanguage]['shareText'];

    this.tweetElm = document.getElementById('basic-tweet');
  }

  mounted() {
    this.tweetElm = document.getElementById('basic-tweet');
    const tweetSite = 'https://datenina.ch/';
    const tweetUrl = 'https://twitter.com/intent/tweet?text=' + this.shareText + ' ' + tweetSite;
    this.tweetElm.setAttribute('href', tweetUrl);
  }

  shareBtn() {
    this.$store.dispatch('shareBtn', this.shareText);
  }
}

