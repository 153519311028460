


































import {Component, Vue} from 'vue-property-decorator';
import BadFinishComponent from "@/components/bad-finish.component.vue";
import FullScreenComponent from "@/components/fullScreen.component.vue";
import GoodFinishComponent from "@/components/good-finish.component.vue";
import StartComponent from "@/components/start.component.vue";
import ChoicesComponent from '@/components/choices.component.vue';

@Component({
  components: {
    BadFinishComponent,
    FullScreenComponent,
    GoodFinishComponent,
    StartComponent,
    ChoicesComponent,
  },
})
export default class VideoComponent extends Vue {
  video: any;
  videoSteps: any;
  videoElm: any;
  currentLanguage: string;
  videoPoster: string;
  currentVideoID: string;
  introText: string;
  linkText: string;
  decAText: string;
  decBText: string;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.video = this.$store.state.video;
    this.currentLanguage = this.$store.state.currentLanguage;
    this.currentVideoID = this.$store.state.video.Id;
    this.videoPoster = this.video.videoPosterUrl;
    this.introText = this.videoSteps['1']['content'][this.currentLanguage].introText;
    this.linkText = this.videoSteps['1']['content'][this.currentLanguage].linkText;
    this.decAText = '';
    this.decBText = '';

  }

  mounted() {
    this.videoElm = document.getElementById('video');
  }

  videoEnded() {
    if (this.videoSteps[this.video.Id]['content'][this.currentLanguage][this.video.decision].badEnd && !this.video.backFromFinish) {
      this.$store.dispatch('setVideoId', this.video.Id)
      this.$store.dispatch('badEnd', true);
    } else if (this.videoSteps[this.video.Id]['content'][this.currentLanguage][this.video.decision].goodEnd == true) {
      this.$store.dispatch('goodEnd', true);
    } else {
      if (this.video.backFromFinish) {
        this.$store.dispatch('backFromFinish', false)
        this.decAText = this.videoSteps[this.video.Id]['content'][this.currentLanguage].decAText;
        this.decBText = this.videoSteps[this.video.Id]['content'][this.currentLanguage].decBText;
      }
      this.$store.state.showChoices = true;
    }
  }
}

