












import {Component, Vue} from "vue-property-decorator";
import SocialMedia from "@/components/social-media.component.vue";


@Component({
  components: {
    SocialMedia
  }
})
export default class GoodFinishComponent extends Vue {
  videoSteps: string;
  video: any;
  currentVideoID: any;
  currentLanguage: string;
  endText: string;
  endTextSmall: string;
  btnText: string;

  constructor() {
    super();
    this.videoSteps = this.$store.state.siteData.steps;
    this.currentVideoID = this.$store.state.video.Id;
    this.currentLanguage = this.$store.state.currentLanguage;

    this.endText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['a'].endText;
    this.endTextSmall = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['a'].endTextSmall;
    this.btnText = this.videoSteps[this.currentVideoID]['content'][this.currentLanguage]['a'].btnText;
  }

  startDate() {
    this.$store.dispatch('startDate');
    setTimeout(() => {
      this.$store.dispatch('playVideo');
    }, 150)
  }
}
